import axios from "axios";
import { toast } from "react-toastify";
import { API_KEY, API_URL } from "../config/constant";
import secureStorage from "../config/encrypt";

const instance = axios.create({
  baseURL: API_URL.COMMON_GATEWAY_API,
  headers: {}
});
instance.interceptors.request.use(
  (request) => {
    let entity_source = localStorage.getItem('entity_source') || 'oto-in';
    let headers = {
      'Authorization': getToken(),
      'Accept-Language': getSelectedLanguage(),
      'source':"WEB",
      'tenant': entity_source
    };
    if(request.apiKey) {
      headers["apiKey"] = request.apiKey;
    }
    if(request.url?.includes("/remove_user")) {
      headers["apiKey"] = API_KEY
    }
    if(!isValidUrl(entity_source,request.url)){
      window.location = '/logout';
    }
    request.headers = headers;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.status !== 200) {
      if (response.data.status === 400){
        toast.error(`${response.data.message}`);
        if(response.data.message === "access_token_expired")
        window.location = '/logout';
    }
  }
    if (response.status === 401) {
      window.location = '/logout';
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status && error.response.status === 401) {
      window.location = '/logout';
    }
    return Promise.reject(error);
  }
);
const getToken = () => {
  let fetchHeader = secureStorage.getItem("authToken");
  return fetchHeader;
}
const getSelectedLanguage = () => {
  return secureStorage.getItem("lang") || 'en'
}
const finaljson= {
  post(URL, body, headers=null) {
    return instance.post(`${URL}`, body, headers);
  },
  put(URL, body) {
    return instance.put(`${URL}`, body);
  },
  get(URL, query) {
    return instance.get(`${URL}`, { params: query });
  }
};
const isValidUrl = (entity_source,url)=>{
let isValid=true;
let redirecTwhenFoundUrl=["/loan/lead/getleads","/loan/lead/lead_dashboard","/loan/report/get_tat_report","/bike-refinance/finance/dashboard","/loan/report/get_funnel_report","/loan/lead/get_api_config_data","/loan/report/get_user_tat_report"]
console.log("entity_source",entity_source,"url",url)
if(redirecTwhenFoundUrl.includes(url) && entity_source==='emp-id'){
  isValid=false;
}
return isValid;
}
export default finaljson;